<template>
  <section class="features" id="features">
    <div class="features__inner">
      <h1>FEATURES</h1>
      <h2>{{ $t("features.main") }}</h2>
      <ul>
        <li>
          <img v-webp:src="require('@/assets/icons/PoweringGraphics.webp')"
            :src="require('@/assets/icons/PoweringGraphics.png')">
          <div>
            <h3>{{ $t("features.poweringgraphics.main") }}</h3>
            <p>{{ $t("features.poweringgraphics.sub") }}</p>
          </div>
        </li>
        <li>
          <img v-webp:src="require('@/assets/icons/EarnToken.webp')" :src="require('@/assets/icons/EarnToken.png')">
          <div>
            <h3>{{ $t("features.earntokens.main") }}</h3>
            <p>{{ $t("features.earntokens.sub") }}</p>
          </div>
        </li>
        <li>
          <img v-webp:src="require('@/assets/icons/App_Web.webp')" :src="require('@/assets/icons/App_Web.png')">
          <div>
            <h3>{{ $t("features.appweb.main") }}</h3>
            <p>{{ $t("features.appweb.sub") }}</p>
          </div>
        </li>
        <li>
          <img v-webp:src="require('@/assets/icons/PaidContentSales.webp')"
            :src="require('@/assets/icons/PaidContentSales.png')">
          <div>
            <h3>{{ $t("features.paidcontentsales.main") }}</h3>
            <p>{{ $t("features.paidcontentsales.sub") }}</p>
          </div>
        </li>
        <li>
          <img v-webp:src="require('@/assets/icons/Expandability.webp')"
            :src="require('@/assets/icons/Expandability.png')">
          <div>
            <h3>{{ $t("features.expandability.main") }}</h3>
            <p>{{ $t("features.expandability.sub") }}</p>
          </div>
        </li>
        <li>
          <img v-webp:src="require('@/assets/icons/YouControlYourAds.webp')"
            :src="require('@/assets/icons/YouControlYourAds.png')">
          <div>
            <h3>{{ $t("features.youcontrolyourads.main") }}</h3>
            <p>{{ $t("features.youcontrolyourads.sub") }}</p>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeaturesComponent'
}
</script>

<style scoped>
.features {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 0 120px 0;
  /*background-color: rgba(255,60,118,1);*/
  background: linear-gradient(130deg, rgba(255, 60, 118, 1) 0%, rgba(255, 155, 183, 1) 100%);
  color: white;
}

.features__inner {
  width: 80vw;
  max-width: 1012px;
  text-align: center;
}

img {
  width: 100px;
  height: 100px;
  margin-right: 15px;
}

h1 {
  font-size: 14px;
}

h1:before {
  content: '';
  width: 50px;
  height: 1px;
  display: inline-block;
  background-color: #e1e1e1;
  margin: 0 15px 0 0;
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
  color: #8790af;
}

h1:after {
  content: '';
  width: 50px;
  height: 1px;
  display: inline-block;
  background-color: #e1e1e1;
  margin: 0 0 0 15px;
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
}

h2 {
  font-size: 32px;
  margin: 30px 0 60px 0;
  line-height: 1.4em;
}

p {
  margin: 0;
  font-weight: bold;
}

ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  text-align: left;
  display: flex
}

h3 {
  font-size: 18px;
  margin: 0 0 15px;
}

@media screen and (max-width: 800px) {
  ul {
    grid-template-columns: 1fr;
  }
}
</style>
