
import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { messages, defaultLang } from '../i18n/messages'
import ChangeLangComponent from '@/components/home/ChangeLangComponent.vue';
import HeaderComponent from '@/components/home/HeaderComponent.vue';
import HomeComponent from '@/components/home/HomeComponent.vue';
import AboutComponent from '@/components/home/AboutComponent.vue';
import ForCreatorsComponent from '@/components/home/ForCreatorsComponent.vue';
import WalletComponent from '@/components/home/WalletComponent.vue';
import FeaturesComponent from '@/components/home/FeaturesComponent.vue';
import ContactsComponent from '@/components/home/ContactsComponent.vue';
import FooterComponent from '@/components/home/FooterComponent.vue';

@Options({
  components: {
    ChangeLangComponent,
    HeaderComponent,
    HomeComponent,
    AboutComponent,
    ForCreatorsComponent,
    WalletComponent,
    FeaturesComponent,
    ContactsComponent,
    FooterComponent,
  },
  methods: {
    changeLang: function () {
      let lang = defaultLang;
      switch (this.$route.query.lang) {
        case "ja":
          lang = "ja";
          break;
        case "en":
          lang = "en";
          break;
      }
      this.$i18n.locale = lang;
    },
    setChangeLangMessage: function () {
      // サイトのデフォルト言語とブラウザのデフォルト言語が一致していなかったとき
      this.changeLang = reactive({
        isDisp: false,
        toLang: "",
        message: "",
        buttonText: ""
      });
      let userDefaultLang = defaultLang; // ブラウザのデフォルト言語はサイトのデフォルト言語で初期化する
      if (window.navigator.languages.length > 0) {
        userDefaultLang = window.navigator.languages[0];
        const splitedUserDefaultLang = userDefaultLang.split("-");
        if (splitedUserDefaultLang.length > 0) {
          userDefaultLang = splitedUserDefaultLang[0]; // en-US などの場合は en に変換
        }
      }
      // jaがパラメータなし ... userDefaultLang=ja,query=undefined
      // jaがen ... userDefaultLang=ja,query=en
      // jaがja ... userDefaultLang=ja,query=ja
      // enがパラメータなし ... userDefaultLang=en,query=undefined
      // enがja ... userDefaultLang=en,query=ja
      // enがen ... userDefaultLang=en,query=en
      // その他がパラメータなし ... userDefaultLang=hoge,query=undefined
      // その他がja ... userDefaultLang=hoge,query=ja
      // その他がen ... userDefaultLang=hoge,query=en
      if (userDefaultLang != defaultLang && userDefaultLang != this.$route.query.lang) {
        // 言語変更しますかと提案
        this.changeLang.isDisp = true;
        this.changeLang.toLang = "en";
        this.changeLang.message = messages.change.en.main;
        this.changeLang.buttonText = messages.change.en.button;
      }
      if (userDefaultLang == defaultLang && userDefaultLang != this.$route.query.lang) {
        this.changeLang.isDisp = false;
        this.changeLang.toLang = "en";
        this.changeLang.message = messages.change.en.main;
        this.changeLang.buttonText = messages.change.en.button;
      }
    }
  },
  watch: {
    $route(to, from) {
      if (from.query.lang != to.query.lang) {
        // パラメータ ?lang= が変わった場合
        // this.changeLang(); と同じ内容。なぜか呼び出せない
        let lang = defaultLang;
        switch (this.$route.query.lang) {
          case "ja":
            lang = "ja";
            break;
          case "en":
            lang = "en";
            break;
        }
        this.$i18n.locale = lang;
        //
        this.changeLang.isDisp = false;
      }
    }
  },
  created: function () {
    // パラメータ ?lang= で言語が指定されていた場合それを使う
    this.changeLang();
    // 言語が一致していなかった場合にメッセージを出す
    this.setChangeLangMessage();
  }
})
export default class HomeView extends Vue { }
