import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChangeLangComponent = _resolveComponent("ChangeLangComponent")!
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_HomeComponent = _resolveComponent("HomeComponent")!
  const _component_AboutComponent = _resolveComponent("AboutComponent")!
  const _component_ForCreatorsComponent = _resolveComponent("ForCreatorsComponent")!
  const _component_WalletComponent = _resolveComponent("WalletComponent")!
  const _component_FeaturesComponent = _resolveComponent("FeaturesComponent")!
  const _component_ContactsComponent = _resolveComponent("ContactsComponent")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_ChangeLangComponent, {
      toLang: _ctx.changeLang.toLang,
      message: _ctx.changeLang.message,
      buttonText: _ctx.changeLang.buttonText
    }, null, 8, ["toLang", "message", "buttonText"]), [
      [_vShow, _ctx.changeLang.isDisp]
    ]),
    _createVNode(_component_HeaderComponent),
    _createVNode(_component_HomeComponent),
    _createVNode(_component_AboutComponent),
    _createVNode(_component_ForCreatorsComponent),
    _createVNode(_component_WalletComponent),
    _createVNode(_component_FeaturesComponent),
    _createVNode(_component_ContactsComponent),
    _createVNode(_component_FooterComponent)
  ]))
}