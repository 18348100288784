import { en_home } from "./en/en_home";
import { ja_home } from "./ja/ja_home";
import { en_about } from "./en/en_about";
import { ja_about } from "./ja/ja_about";
import { en_forcreators } from "./en/en_forcreators";
import { ja_forcreators } from "./ja/ja_forcreators";
import { en_wallet } from "./en/en_wallet";
import { ja_wallet } from "./ja/ja_wallet";
import { en_features } from "./en/en_features";
import { ja_features } from "./ja/ja_features";
import { en_contacts } from "./en/en_contacts";
import { ja_contacts } from "./ja/ja_contacts";

export const defaultLang = "ja";
export const messages = {
  change: {
    ja: {
      main: "日本語版に切り替えますか？",
      button: "切り替える"
    },
    en: {
      main: "This site has a English version. Do you want to switch?",
      button: "Switch"
    }
  },
  en: {
    home: en_home,
    about: en_about,
    forcreators: en_forcreators,
    wallet: en_wallet,
    features: en_features,
    contacts: en_contacts
  },
  ja: {
    home: ja_home,
    about: ja_about,
    forcreators: ja_forcreators,
    wallet: ja_wallet,
    features: ja_features,
    contacts: ja_contacts
  }
}