<template>
  <section class="wallet" id="wallet">
    <div class="wallet__inner">
      <h1>WALLET</h1>
      <h2>{{ $t("wallet.main") }}</h2>
      <div>
        <p>{{ $t("wallet.sub1") }}</p>
        <p>{{ $t("wallet.sub2") }}</p>
        <p>{{ $t("wallet.sub3") }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WalletComponent'
}
</script>

<style scoped>
.wallet {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  padding: 120px 0 120px 0;
}

.wallet__inner {
  width: 80vw;
  max-width: 700px;
  padding-left: 10vw;
}

h1 {
  font-size: 14px;
}

h1:after {
  content: '';
  width: 50px;
  height: 1px;
  display: inline-block;
  background-color: #e1e1e1;
  margin: 0 0 0 15px;
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
}

h2 {
  font-size: 32px;
  margin: 30px 0 30px 0;
  line-height: 1.4em;
  color: #434345;
}

p {
  margin: 0;
  font-weight: bold;
}
</style>