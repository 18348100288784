export const en_features = {
  main: "Graphical Creator Token Econony",
  poweringgraphics: {
    main: "Powering Graphics",
    sub: "Graphic designers, illustrators, cartoonists, and you out there. We are here to help all graphic content and creators."
  },
  earntokens: {
    main: "Earn Tokens",
    sub: "Earn tokens when users simply view ads, and creators’ posts are viewed. Tokens will be redeemable for Amazon gift certificates or cash."
  },
  appweb: {
    main: "App & Web",
    sub: "Ubiq recognizes text and other elements in graphics and uploads them to both the app and web versions at the same time, allowing your content to be found on the web."
  },
  paidcontentsales: {
    main: "Paid Content Sales",
    sub: "Creators can publish their content for free, but they can also choose to sell their content for a fee, create a paid series and sell monthly memberships, or offer their content in a variety of other ways."
  },
  expandability: {
    main: "Expandability",
    sub: "You can freely place links on top of graphics. You can use it in various ways, such as to transfer to your own site, service or product page, or to post affiliate links. In the future, we plan to support embedding of videos and external services."
  },
  youcontrolyourads: {
    main: "You control your ads",
    sub: "Users can set the amount of ad serving, whether or not to have targeted ads, etc. The ease of earing tokens fluctuates depending on the user's ad settings."
  }
}