<template>
  <section class="contacts" id="contacts">
    <div class="contacts__inner">
      <h1>CONTACT US</h1>
      <h2>{{ $t("contacts.main") }}</h2>
      <ul>
        <li>
          <img v-webp:src="require('@/assets/icons/location.webp')" :src="require('@/assets/icons/location.png')">
          <p>{{ $t("contacts.location") }}</p>
        </li>
        <li>
          <img v-webp:src="require('@/assets/icons/phone.webp')" :src="require('@/assets/icons/phone.png')">
          <p>{{ $t("contacts.phone") }}</p>
        </li>
        <li>
          <img v-webp:src="require('@/assets/icons/mail.webp')" :src="require('@/assets/icons/mail.png')">
          <p>{{ $t("contacts.mail", { address: 'info@ubiq-world.com' }) }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactsComponent'
}
</script>

<style scoped>
.contacts {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 0 120px 0;
}

.contacts__inner {
  width: 80vw;
  max-width: 1012px;
  text-align: center;
}

img {
  width: 60px;
  height: 60px;
  margin: 15px;
}

h1 {
  font-size: 14px;
}

h1:before {
  content: '';
  width: 50px;
  height: 1px;
  display: inline-block;
  background-color: #e1e1e1;
  margin: 0 15px 0 0;
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
  color: #8790af;
}

h1:after {
  content: '';
  width: 50px;
  height: 1px;
  display: inline-block;
  background-color: #e1e1e1;
  margin: 0 0 0 15px;
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
}

h2 {
  font-size: 32px;
  margin: 30px 0 60px 0;
  line-height: 1.4em;
  color: #434345;
}

p {
  margin: 0;
  font-weight: bold;
}

ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 700px) {
  ul {
    grid-template-columns: 1fr;
  }
}
</style>
