<template>
  <section class="changelang" id="changelang">
    <p>{{ message }}</p>
    <button v-on:click="() => { $router.push({ name: 'home', query: { lang: toLang } }) }">{{ buttonText }}</button>
  </section>
</template>

<script>

export default {
  name: 'ChangeLangComponent',
  props: {
    toLang: String,
    message: String,
    buttonText: String
  }
}
</script>

<style scoped>
.changelang {
  padding: 28px;
  background-color: #353534;
  font-size: 0.9rem;
}

p {
  margin: 0;
  margin-right: 25px;
  padding: 0;
  display: inline-block;
  color: white;
}

button {
  display: inline;
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none;

  margin: 0;
  padding: 10px;
  background-color: rgba(255, 60, 118, 1);
  border-radius: 10px;
  font-size: 0.9rem;
  color: white;
}
</style>