<template>
  <section class="home" id="home" v-bind:class="{ 'webp': isWebpSupported, 'no-webp': !isWebpSupported }">
    <div class="home__inner">
      <h1>{{ $t("home.main") }}</h1>
      <p>{{ $t("home.sub") }}</p>
      <div class="downloadicons">
        <a href="https://play.google.com/store/apps/details?id=com.ubiqworld.ubiq"><img alt="Get it on Google Play"
            src="@/assets/download_google.png" /></a>
        <a
          href="https://apps.apple.com/us/app/ubiq-%E3%82%AF%E3%83%AA%E3%82%A8%E3%82%A4%E3%82%BF%E3%83%BC%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%82%A8%E3%82%B3%E3%83%8E%E3%83%9F%E3%83%BC/id6444285427"><img
            src="@/assets/download_apple.svg" alt='Download on the App Store'></a>
      </div>
    </div>
  </section>
</template>

<script>
import isWebpSupported from '@/utils/isWebpSupported'
export default {
  name: 'HomeComponent',
  computed: {
    isWebpSupported() {
      return isWebpSupported
    }
  }
}
</script>

<style scoped>
.home {
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.webp {
  background: url(@/assets/homebg.webp);
}

.no-webp {
  background: url(@/assets/homebg.jpeg);
}

.home::after {
  content: '';
  backdrop-filter: blur(2px);
  background: linear-gradient(45deg, rgba(255, 60, 118, 0.6) 0%, rgba(255, 155, 183, 0.6) 100%);
  position: absolute;
  width: 100%;
  height: 100vh;
}

@media screen and (max-width: 540px) {

  .home,
  .home::after {
    height: calc(100vh + 240px);
  }
}

.home__inner {
  width: 80vw;
  max-width: 800px;
  padding-left: 10vw;
  z-index: 1;
  color: white;
}

.downloadicons {
  margin-top: 32px;
}

.downloadicons a,
.downloadicons img {
  height: 4em;
  padding-right: 8px;
}

h1 {
  margin: 0;
  font-size: 60px;
  line-height: 80px;
}

p {
  font-weight: bold;
}
</style>
