export const ja_features = {
  main: "Graphical Creator Token Econony",
  poweringgraphics: {
    main: "グラフィックを加速",
    sub: "グラフィックデザイナー、イラストレーター、漫画家、そしてそこのあなた。すべてのグラフィックコンテンツとクリエイターの力になります。"
  },
  earntokens: {
    main: "トークンを稼ぐ",
    sub: "ユーザーは広告を見るだけで、クリエイターは自分の投稿にViewがつくとトークン報酬を獲得できます。トークンはAmazonギフト券や現金に交換できる予定です。"
  },
  appweb: {
    main: "アプリだけでなくwebでも",
    sub: "グラフィック内の文字等の要素を認識しアプリ版とWeb版に同時アップロードを行うので、あなたのコンテンツにWeb検索からの流入を実現します。"
  },
  paidcontentsales: {
    main: "有料コンテンツ販売",
    sub: "クリエイターは基本的に無料でコンテンツを公開しますが、その他にも自分のコンテンツを有料販売したり、有料のシリーズを制作し月額メンバーシップを販売したり、さまざまなコンテンツの提供方法を選べます。"
  },
  expandability: {
    main: "拡張性",
    sub: "グラフィックの上に自由にリンクを配置できます。自社サイトやサービス・商品ページに遷移させたり、アフェリエイトリンクを貼ったり、使い方はいろいろ。今後は動画や外部サービスの埋め込みにも対応予定です。"
  },
  youcontrolyourads: {
    main: "広告をコントロールするのは、あなた",
    sub: "ユーザーが広告配信量、ターゲティング広告の有無等を設定することができます。ユーザーの広告設定に応じて、トークンの貯まりやすさが変動します。"
  }
}