<template>
  <section class="about" id="about">
    <div class="about__inner">
      <img v-webp:src="require('@/assets/logo.webp')" :src="require('@/assets/logo.png')" alt="ubiq logo">
      <h1>ABOUT Ubiq</h1>
      <h2>{{ $t("about.main1") }}<br>{{ $t("about.main2") }}</h2>
      <div class="about__inner__text">
        <p>{{ $t("about.sub") }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutComponent'
}
</script>

<style scoped>
.about {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 0 120px 0;
}

.about__inner {
  width: 80vw;
  max-width: 700px;
  text-align: center;
}

img {
  width: 60px;
}

h1 {
  font-size: 14px;
}

h1:before {
  content: '';
  width: 50px;
  height: 1px;
  display: inline-block;
  background-color: #e1e1e1;
  margin: 0 15px 0 0;
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
  color: #8790af;
}

h1:after {
  content: '';
  width: 50px;
  height: 1px;
  display: inline-block;
  background-color: #e1e1e1;
  margin: 0 0 0 15px;
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
}

h2 {
  font-size: 32px;
  margin: 60px 0 60px 0;
  line-height: 1.4em;
  color: rgba(255, 60, 118, 1);
  background: linear-gradient(130deg, rgba(255, 60, 118, 1) 0%, rgba(255, 155, 183, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p {
  margin: 0;
  font-weight: bold;
}
</style>