<template>
  <!--
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  -->
  <router-view/>
</template>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #fcfcff;
}
#app {
  max-width: 2560px;
  font-family: Helvetica, 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #8790af;
  font-size: 16px;
  line-height: 32px;
  margin:  0 auto;
  padding: 0;
  background-color: white;
}
</style>
