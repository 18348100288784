<template>
  <header>
    <img v-webp:src="require('@/assets/textlogo_white.webp')" :src="require('@/assets/textlogo_white.png')" alt="Ubiq">
    <ul>
      <li v-bind:class="{ hide: !isOpening }"><a href="#home" @click="() => isOpening = !isOpening">Home</a></li>
      <li v-bind:class="{ hide: !isOpening }"><a href="#about" @click="() => isOpening = !isOpening">About Ubiq</a></li>
      <li v-bind:class="{ hide: !isOpening }"><a href="#forcreators" @click="() => isOpening = !isOpening">For
          Creators</a>
      </li>
      <li v-bind:class="{ hide: !isOpening }"><a href="#wallet" @click="() => isOpening = !isOpening">Wallet</a></li>
      <li v-bind:class="{ hide: !isOpening }"><a href="#features" @click="() => isOpening = !isOpening">Features</a>
      </li>
      <li v-bind:class="{ hide: !isOpening }"><a href="#contacts" @click="() => isOpening = !isOpening">Contacts</a>
      </li>
    </ul>
    <button @click="() => isOpening = !isOpening"><span class="icon-bar icon-bar-top"></span><span
        class="icon-bar"></span><span class="icon-bar"></span></button>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      isOpening: false
    }
  },
  methods: {
    onResize() {
      if (window.innerWidth > 950) {
        this.isOpening = false;
      }
    }
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style scoped>
header {
  position: sticky;
  top: 0;
  background: linear-gradient(45deg, rgba(255, 60, 118, 1) 0%, rgba(255, 155, 183, 1) 100%);
  text-align: center;
  z-index: 10;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 20px 0 20px 0;
}

li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px;
}

button {
  width: 40px;
  height: 35px;
  position: absolute;
  top: 25px;
  right: 40px;
  display: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  appearance: none;
  padding: 5px;
  border: 1px solid #ffffff;
}

.icon-bar {
  margin-top: 4px;
  background-color: #fff;
  height: 2px;
  border-radius: 0;
  display: block;
  width: 27px;
}

.icon-bar-top {
  margin-top: 0;
}

.hide {
  display: inline-block;
}

@media screen and (max-width: 950px) {
  ul {
    list-style-type: none;
    margin: 0;
    padding-top: 70px;
  }

  li {
    display: block;
    margin: 0;
    padding: 15px;
  }

  li:hover {
    background: white;
  }

  li:hover>a {
    color: rgba(255, 60, 118, 1);
  }

  button {
    display: block;
  }

  .hide {
    display: none;
  }
}

a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

img {
  height: 40px;
  position: absolute;
  top: 20px;
  left: 40px;
}
</style>
