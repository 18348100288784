import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { messages, defaultLang } from './i18n/messages'
import App from './App.vue'
import router from './router'
import VueWebpImage from 'vue-webp-image'

// 自動で言語を変更する場合
// const DEFAULT_LANG = "en";
// let userDefaultLang = "en";
// if (window.navigator.languages.length > 0) {
//   userDefaultLang = window.navigator.languages[0];
// }
// let locale = "";
// if (userDefaultLang != DEFAULT_LANG) {
//   switch (userDefaultLang) {
//     case "ja":
//       locale = "ja";
//       break;
//     default:
//       locale = "en";
//   }
// }

const i18n = createI18n({
  locale: defaultLang,
  messages,
})

createApp(App).use(router).use(i18n).use(VueWebpImage).mount('#app')
